import fetch_utils from "../fetch_utils";
import ITrads from "../ITrads";

type OdorConcentrationPointsRow = [
  // latitude
  number,
  // longitude
  number,
  // weight
  number
];

interface IIdConfigAndCheckbox {
  id: number;
  checkboxGroupHtml: HTMLDivElement;
  label: string;
}

interface IHeatmapAndLabel {
  heatmap: google.maps.visualization.HeatmapLayer;
  label: string;
}

interface IDistanceWeight {
  distance: number;
  weight: number;
}

let trads: ITrads;

const sourceEmission: google.maps.Marker[] = [];

/**
 * https://gis.stackexchange.com/questions/246322/get-the-inverse-of-default-heat-map-gradient-in-google-maps-javascript-api
 */
const gradientHeatmap = [
  "rgba(102, 255, 0, 0)",
  "rgba(102, 255, 0, 1)",
  "rgba(147, 255, 0, 1)",
  "rgba(193, 255, 0, 1)",
  "rgba(238, 255, 0, 1)",
  "rgba(244, 227, 0, 1)",
  "rgba(249, 198, 0, 1)",
  "rgba(255, 170, 0, 1)",
  "rgba(255, 113, 0, 1)",
  "rgba(255, 57, 0, 1)",
  "rgba(255, 0, 0, 1)",
];

const getAjaxOdorModellingConfig = async (): Promise<
  { id: number; label: string; latitude: number; longitude: number }[]
> => {
  const pathname = `/Enqueteur/modelling_odor_concentration/php/ajax_get_odor_modelling_config.php`;
  const odorModellingConfigIdsRaw = await fetch_utils.boilerplateGet(pathname);
  if (odorModellingConfigIdsRaw === false) {
    return;
  }
  try {
    return JSON.parse(odorModellingConfigIdsRaw);
  } catch (error) {
    fetch_utils.catchJsonParseError(pathname, odorModellingConfigIdsRaw);
    return [];
  }
};

const placeSourceEmission = (
  map: google.maps.Map,
  point: google.maps.LatLng | google.maps.LatLngLiteral
) => {
  return new google.maps.Marker({
    position: point,
    map: map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "orange",
      fillOpacity: 0.6,
      scale: 7,
      strokeWeight: 0.75,
      strokeColor: "blue",
      anchor: new google.maps.Point(0, 0),
    },
    zIndex: 1000,
    draggable: false,
  });
};

const addCheckbox = (labelText: string) => {
  const parentDiv = document.getElementById("expoll-impact");
  const checkboxGroup = document.createElement("div");
  parentDiv.appendChild(checkboxGroup);
  const checkbox = document.createElement("input");
  checkbox.type = "checkbox";
  checkbox.checked = true;
  checkboxGroup.appendChild(checkbox);
  const label = document.createElement("span");
  label.appendChild(document.createTextNode(labelText));
  label.style.marginRight = "1em";
  checkboxGroup.appendChild(label);
  const loader = document.createElement("div");
  loader.classList.add("lds-hourglass");
  checkboxGroup.appendChild(loader);
  return checkboxGroup;
};

const displayOdorConcentration = async (
  date: string,
  time: string,
  aOdorModellingConfigId: number
): Promise<{ location: google.maps.LatLng; weight: number }[]> => {
  // If odor_modelling_config_id is not owned by ODO, nothing is returned
  const pathname = `/Enqueteur/modelling_odor_concentration/php/ajax_odor_concentration.php?date=${date}&time=${time}&odor_modelling_config_id=${aOdorModellingConfigId}`;

  const odorConcentrationPointsRaw = await fetch_utils.boilerplateGet(pathname);
  if (odorConcentrationPointsRaw === false) {
    return;
  }
  let odorConcentrationPoints: OdorConcentrationPointsRow[] = [];
  try {
    // I use JSON array instead of two `String.splits()`. It's enough for our goal.
    // Maybe parse JSON array is better than two String.splits.
    // https://www.measurethat.net/Benchmarks/Show/9634/1/jsonparse-vs-stringsplit
    // https://stackoverflow.com/questions/58279818/to-optimise-performance-should-i-store-web-data-as-csv-or-json
    // https://leanylabs.com/blog/js-csv-parsers-benchmarks/
    odorConcentrationPoints = JSON.parse(odorConcentrationPointsRaw);
  } catch (error) {
    fetch_utils.catchJsonParseError(pathname, odorConcentrationPointsRaw);
    return;
  }
  return odorConcentrationPoints.map((aPoint: OdorConcentrationPointsRow) => {
    return {
      location: new google.maps.LatLng(aPoint[0], aPoint[1]),
      weight: aPoint[2],
    };
  });
};

const addPoints = async (
  map: google.maps.Map,
  date: string,
  time: string,
  aOdorModellingConfigId: number
): Promise<google.maps.visualization.HeatmapLayer> => {
  const googleHeatmapPoints = await displayOdorConcentration(
    date,
    time,
    aOdorModellingConfigId
  );
  let maxWeight = 0;
  googleHeatmapPoints.forEach((aPoint) => {
    if (aPoint.weight > maxWeight) {
      maxWeight = aPoint.weight;
    }
  });
  return new google.maps.visualization.HeatmapLayer({
    data: googleHeatmapPoints,
    map: map,
    maxIntensity: maxWeight,
    gradient: gradientHeatmap,
  });
};

const addScale = (checkboxGroup: HTMLDivElement, maxIntensity: string) => {
  const legend = document.createElement("div");
  legend.style.height = "1em";
  legend.style.width = "100%";
  const linearGradient = gradientHeatmap.join(",");
  legend.style.background = `linear-gradient(to right, ${linearGradient})`;
  checkboxGroup.appendChild(legend);
  const scale = document.createElement("div");
  scale.style.display = "flex";
  scale.style.width = "100%";
  scale.style.height = "1em";
  scale.style.justifyContent = "space-between";
  const minScale = document.createElement("span");
  minScale.appendChild(document.createTextNode("0"));
  const maxScale = document.createElement("span");
  maxScale.appendChild(document.createTextNode(maxIntensity));
  scale.appendChild(minScale);
  scale.appendChild(maxScale);
  checkboxGroup.appendChild(scale);
};

const heatmapAdded = (
  heatmap: google.maps.visualization.HeatmapLayer,
  checkboxGroup: HTMLDivElement,
  map: google.maps.Map
) => {
  checkboxGroup.querySelector(".lds-hourglass").remove();
  if (heatmap.getData().getLength() === 0) {
    const noPointWarnignMessage = document.createElement("div");
    noPointWarnignMessage.style.color = "green";
    noPointWarnignMessage.appendChild(
      document.createTextNode(trads.aucune_odeur)
    );
    checkboxGroup.appendChild(noPointWarnignMessage);
    return;
  }
  checkboxGroup.querySelector("input").addEventListener("change", function () {
    if (this.checked) {
      heatmap.setMap(map);
    } else {
      heatmap.setMap(null);
    }
  });
  const maxIntensity = heatmap.get("maxIntensity");
  addScale(checkboxGroup, String(maxIntensity));
};

/**
 * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
 */
const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const deg2rad = (deg: number) => deg * (Math.PI / 180);

  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const findNearestPoint = (
  aHeatmapAndLabel: IHeatmapAndLabel,
  mapsMouseEvent: google.maps.MapMouseEvent,
  nearerWeightedLocation: IDistanceWeight
) => {
  aHeatmapAndLabel.heatmap
    .getData()
    .forEach((weightedLocation: google.maps.visualization.WeightedLocation) => {
      const distance = getDistanceFromLatLonInKm(
        mapsMouseEvent.latLng.lat(),
        mapsMouseEvent.latLng.lng(),
        weightedLocation.location.lat(),
        weightedLocation.location.lng()
      );
      if (distance < nearerWeightedLocation.distance) {
        nearerWeightedLocation.distance = distance;
        nearerWeightedLocation.weight = weightedLocation.weight;
      }
    });
};

const displayValueNearer = (
  map: google.maps.Map,
  heatmapAndLabels: IHeatmapAndLabel[]
) => {
  map.addListener("click", (mapsMouseEvent: google.maps.MapMouseEvent) => {
    const labelAndWeight = heatmapAndLabels.reduce<string>(
      (accumulator, aHeatmapAndLabel): string => {
        let nearerWeightedLocation: IDistanceWeight = {
          distance: 12742,
          weight: 0,
        };
        findNearestPoint(
          aHeatmapAndLabel,
          mapsMouseEvent,
          nearerWeightedLocation
        );
        if (nearerWeightedLocation.distance < 0.05) {
          accumulator += `<br /> ${aHeatmapAndLabel.label}: ${nearerWeightedLocation.weight} uo`;
        }
        return accumulator;
      },
      ""
    );
    if (labelAndWeight !== "") {
      new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
        content: `Expoll Impact ${labelAndWeight}`,
      }).open(map);
    }
  });
};

const hideDisplayHeatmapZoom = (
  map: google.maps.Map,
  heatmapAndLabels: IHeatmapAndLabel[]
) => {
  heatmapAndLabels.forEach((aHeatmapAndLabel) => {
    const messageExpollImpactNotShown = document.getElementById(
      "expoll-impact-not-shown"
    );
    const heatmapZoom = 12;
    if (map.getZoom() < heatmapZoom) {
      aHeatmapAndLabel.heatmap.setMap(null);
      messageExpollImpactNotShown.style.display = "block";
    } else {
      aHeatmapAndLabel.heatmap.setMap(map);
      messageExpollImpactNotShown.style.display = "none";
    }
  });
};

const hideDisplayHeatmapEvent = (
  map: google.maps.Map,
  heatmapAndLabels: IHeatmapAndLabel[]
) => {
  hideDisplayHeatmapZoom(map, heatmapAndLabels);
  map.addListener("center_changed", () => {
    window.setTimeout(() => {
      hideDisplayHeatmapZoom(map, heatmapAndLabels);
    }, 3000);
  });
};

const init = async (
  map: google.maps.Map,
  date: string,
  time: string,
  traductions: ITrads
): Promise<void> => {
  trads = traductions;
  const odorModellingConfig = await getAjaxOdorModellingConfig();
  if (odorModellingConfig.length === 0) {
    alert(`No odor modelling config retrieved. Please add at least one.`);
    return;
  }
  const idConfigAndCheckbox: IIdConfigAndCheckbox[] = [];
  odorModellingConfig.forEach((aOdorModellingConfig) => {
    idConfigAndCheckbox.push({
      id: aOdorModellingConfig.id,
      checkboxGroupHtml: addCheckbox(aOdorModellingConfig.label),
      label: aOdorModellingConfig.label,
    });
    sourceEmission.push(
      placeSourceEmission(
        map,
        new google.maps.LatLng(
          aOdorModellingConfig.latitude,
          aOdorModellingConfig.longitude
        )
      )
    );
  });
  const heatmapAndLabels: IHeatmapAndLabel[] = [];
  for (const aIdAndCheckbox of idConfigAndCheckbox) {
    // Let synchronous to avoid kill server by too heavy process
    const aHeatmap = await addPoints(map, date, time, aIdAndCheckbox.id);
    heatmapAdded(aHeatmap, aIdAndCheckbox.checkboxGroupHtml, map);
    heatmapAndLabels.push({ heatmap: aHeatmap, label: aIdAndCheckbox.label });
  }
  displayValueNearer(map, heatmapAndLabels);
  hideDisplayHeatmapEvent(map, heatmapAndLabels);
};

const displaySourcesEmission = (map: google.maps.Map, display: boolean) => {
  sourceEmission.forEach((aSourceEmission) => {
    if(display) {
      aSourceEmission.setMap(map);
    } else {
      aSourceEmission.setMap(null);
    }
  })
}

export default {
  displaySourcesEmission,
  init,
}
