import fetch_utils from "./fetch_utils";
import ITrads from "./ITrads";

class PagePanelisteReportingWithoutAccount {
  private form: HTMLFormElement;
  private inputLatitude: HTMLInputElement;
  private inputLongitude: HTMLInputElement;
  private formStep1: HTMLElement;
  private formStep2: HTMLElement;
  private formStep3: HTMLElement;
  private formStep4: HTMLElement;
  private pushPinIcon: google.maps.Marker;
  private tryGeolocation: HTMLDivElement;
  private noGeolocation: HTMLDivElement;
  private messageAddressRequired: HTMLDivElement;

  constructor(
    private map: google.maps.Map,
    private traductions: ITrads,
    latitude: number,
    longitude: number
  ) {
    this.form = document.getElementById(
      "form-reporting-odor"
    ) as HTMLFormElement;
    this.inputLatitude = document.querySelector(
      "input[name='latitude']"
    ) as HTMLInputElement;
    this.inputLongitude = document.querySelector(
      "input[name='longitude']"
    ) as HTMLInputElement;
    this.formStep1 = document.getElementById("form-step-1");
    this.formStep2 = document.getElementById("form-step-2");
    this.formStep3 = document.getElementById("form-step-3");
    this.formStep4 = document.getElementById("form-step-4");

    this.inputLatitude.value = String(0);
    this.inputLongitude.value = String(0);
    this.tryGeolocation = document.getElementById(
      "tryGeolocation"
    ) as HTMLDivElement;
    this.noGeolocation = document.getElementById(
      "noGeolocation"
    ) as HTMLDivElement;

    this.messageAddressRequired = document.getElementById(
      "address-required"
    ) as HTMLDivElement;

    this.clickButtonSubmitEvent();
    this.submitFormEvent();
    this.caracterizationOdorStep();
    this.gotoForm();
    this.pushPinIcon = this.addPushPin(map, latitude, longitude);
    this.geolocation();
    this.buttonBrowserGeolocation();
    const searchBox = this.addressAutocompletion(map);
    this.buttonAddressGeolocation(map, searchBox);
    this.moveMapEvent(map);
  }

  private sendEmailWithFormData = async (form: HTMLFormElement) => {
    const formData = new FormData(form);
    const payload = {};
    formData.forEach((value, key) => (payload[key] = value));
    const pathname = "/Paneliste/ajax-reporting-without-account.php";
    const dataRaw = await fetch_utils.boilerplatePost(pathname, payload);
    if (dataRaw === false) {
      return;
    }
    try {
      JSON.parse(dataRaw);
    } catch (error) {
      fetch_utils.catchJsonParseError(pathname, dataRaw);
      return;
    }
    this.formStep1.classList.add("display-none");
    this.formStep2.classList.add("display-none");
    this.formStep3.classList.add("display-none");
    this.formStep4.classList.remove("display-none");
    const caracterizationOdorRadioElements = document.getElementsByName(
      "radio_icone_caracterisation_odeur"
    );
    caracterizationOdorRadioElements.forEach(
      (aRadioElement: HTMLInputElement) => {
        aRadioElement.checked = false;
        aRadioElement.parentElement.classList.remove(
          "a_caracterisation_odeur--checked"
        );
      }
    );
  };

  private clickButtonSubmitEvent = () => {
    const buttonSubmit = document.getElementById("button-form-submit");
    buttonSubmit.addEventListener("click", () => {
      this.sendEmailWithFormData(this.form);
    });
  };

  private submitFormEvent = () => {
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.sendEmailWithFormData(e.target as HTMLFormElement);
    });
  };

  private caracterizationOdorStep = () => {
    const caracterizationOdorRadioElements = document.getElementsByName(
      "radio_icone_caracterisation_odeur"
    );
    caracterizationOdorRadioElements.forEach((aRadioElement) => {
      aRadioElement.addEventListener("click", (mouseEvent: MouseEvent) => {
        caracterizationOdorRadioElements.forEach((aRadioElmt) => {
          aRadioElmt.parentElement.classList.remove(
            "a_caracterisation_odeur--checked"
          );
        });
        (mouseEvent.target as HTMLInputElement).parentElement.classList.add(
          "a_caracterisation_odeur--checked"
        );
        document
          .getElementById("please-select-an-odor")
          .classList.add("display-none");
      });
    });
  };

  private gotoForm = () => {
    const gotoForm = document.querySelectorAll("[goto-form]");
    gotoForm.forEach((anElement) => {
      anElement.addEventListener("click", () => {
        const step = anElement.getAttribute("goto-form");
        const firstname = document.querySelector(
          "input[name='firstname']"
        ) as HTMLInputElement;
        const lastname = document.querySelector(
          "input[name='lastname']"
        ) as HTMLInputElement;
        const email = document.querySelector(
          "input[name='email']"
        ) as HTMLInputElement;
        const createAccount = document.querySelector(
          "input[name='create_account']"
        ) as HTMLInputElement;
        const radioIconeCaracterizationOdor = document.querySelector(
          "input[name='radio_icone_caracterisation_odeur']"
        ) as HTMLInputElement;
        switch (step) {
          case "step-1":
            this.formStep1.classList.remove("display-none");
            this.formStep2.classList.add("display-none");
            this.formStep3.classList.add("display-none");
            this.formStep4.classList.add("display-none");
            break;
          case "step-2":
            email.setCustomValidity("");
            if (
              !firstname.reportValidity() ||
              !lastname.reportValidity() ||
              !email.reportValidity()
            ) {
              return;
            }
            if (createAccount.checked && email.value === "") {
              email.setCustomValidity(this.traductions.email_mandatory);
              email.reportValidity();
              return;
            } else {
              email.setCustomValidity("");
            }
            this.formStep1.classList.add("display-none");
            this.formStep2.classList.remove("display-none");
            this.formStep3.classList.add("display-none");
            this.formStep4.classList.add("display-none");
            break;
          case "step-3":
            if (!radioIconeCaracterizationOdor.reportValidity()) {
              document
                .getElementById("please-select-an-odor")
                .classList.remove("display-none");
              return;
            }
            this.formStep1.classList.add("display-none");
            this.formStep2.classList.add("display-none");
            this.formStep3.classList.remove("display-none");
            this.formStep4.classList.add("display-none");
            break;
        }
      });
    });
  };

  private geolocation = () => {
    this.tryGeolocation.classList.remove("display-none");
    this.noGeolocation.classList.add("display-none");
    if (!("geolocation" in navigator)) {
      this.inputLatitude.value =
        "Geolocation not supported into client browser";
      this.inputLongitude.value =
        "Geolocation not supported into client browser";
      this.tryGeolocation.classList.add("display-none");
      return;
    }
    const geolocationSuccess = (position: GeolocationPosition) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.inputLatitude.value = String(latitude);
      this.inputLongitude.value = String(longitude);
      const latLng = new google.maps.LatLng(latitude, longitude);
      this.map.setCenter(latLng);
      this.map.setZoom(11);
      this.tryGeolocation.classList.add("display-none");
      this.noGeolocation.classList.add("display-none");
    };
    const geolocationError = () => {
      this.inputLatitude.value = "Geolocation blocked";
      this.inputLongitude.value = "Geolocation blocked";
      this.tryGeolocation.classList.add("display-none");
      this.noGeolocation.classList.remove("display-none");
    };
    navigator.geolocation.getCurrentPosition(
      geolocationSuccess,
      geolocationError
    );
  };

  private buttonBrowserGeolocation = () => {
    document
      .getElementById("navigator-geolocation")
      .addEventListener("click", () => {
        this.tryGeolocation.classList.remove("display-none");
        this.noGeolocation.classList.add("display-none");
        setTimeout(() => {
          this.geolocation();
          // Timeout otherwise if geolocalisation is not available we don't see
          // text changed and we believe nothing happens
        }, 1000);
      });
  };

  private googleGetPlaces = (
    map: google.maps.Map,
    searchBox: google.maps.places.SearchBox
  ) => {
    const places = searchBox.getPlaces();

    if (!places || places.length == 0) {
      return;
    }

    this.messageAddressRequired.classList.add("display-none");

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();

    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  };

  private addressAutocompletion = (
    map: google.maps.Map
  ): google.maps.places.SearchBox => {
    // Create the search box and link it to the UI element.
    const input = document.querySelector(
      "input[name='factory_address']"
    ) as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      this.googleGetPlaces(map, searchBox);
    });
    return searchBox;
  };

  private buttonAddressGeolocation = (
    map: google.maps.Map,
    searchBox: google.maps.places.SearchBox
  ) => {
    const button = document.getElementById("address-geolocation");
    button.addEventListener("click", () => {
      const addressInput = document.querySelector(
        "input[name='factory_address']"
      ) as HTMLInputElement;
      if (addressInput.value.trim() === "") {
        addressInput.setCustomValidity("Invalid");
        addressInput.reportValidity();
        this.messageAddressRequired.classList.remove("display-none");
        return;
      }
      this.messageAddressRequired.classList.add("display-none");
      addressInput.setCustomValidity("");
      this.googleGetPlaces(map, searchBox);
    });
  };

  private addPushPin = (
    map: google.maps.Map,
    latitude: number,
    longitude: number
  ) => {
    return new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map: map,
      label: { text: "📍", fontSize: "2rem" },
      icon: { path: google.maps.SymbolPath.CIRCLE, scale: 0 },
    });
  };

  private moveMapEvent = (map: google.maps.Map) => {
    map.addListener("center_changed", () => {
      const mapCenter = map.getCenter();
      this.pushPinIcon.setPosition(mapCenter);
      window.setTimeout(() => {
        this.inputLatitude.value = String(mapCenter.lat());
        this.inputLongitude.value = String(mapCenter.lng());
      }, 1000);
    });
  };
}

export default (
  map: google.maps.Map,
  traductions: ITrads,
  latitude: number,
  longitude: number
) => {
  new PagePanelisteReportingWithoutAccount(
    map,
    traductions,
    latitude,
    longitude
  );
};
