import { Libraries, Loader } from "@googlemaps/js-api-loader";

import odorConcentrationPoints from "./odorConcentrationPoints";
import placePaneliste from "./placePaneliste";
import pointsDInteret from "./pointsDInteret";
import afficheQuartier from "./afficheQuartier";
import "js-info-bubble";

const divMapId = "map";

const addGoogleDistanceFromPrototype = () => {
  (google.maps.LatLng.prototype as any).distanceFrom = function (
    newLatLng: google.maps.LatLng
  ) {
    const lat1 = this.lat();
    const radianLat1 = lat1 * (Math.PI / 180);
    const lng1 = this.lng();
    const radianLng1 = lng1 * (Math.PI / 180);
    const lat2 = newLatLng.lat();
    const radianLat2 = lat2 * (Math.PI / 180);
    const lng2 = newLatLng.lng();
    const radianLng2 = lng2 * (Math.PI / 180);
    const earth_radius = 6378100; // (km = 6378.1) OR (miles = 3959) - radius of the earth
    const diffLat = radianLat1 - radianLat2;
    const diffLng = radianLng1 - radianLng2;
    const sinLat = Math.sin(diffLat / 2);
    const sinLng = Math.sin(diffLng / 2);
    const a =
      Math.pow(sinLat, 2.0) +
      Math.cos(radianLat1) * Math.cos(radianLat2) * Math.pow(sinLng, 2.0);
    const distance = earth_radius * 2 * Math.asin(Math.min(1, Math.sqrt(a)));
    return distance;
  };
};

/**
 * https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
 */
const loadGoogleMap = async (
  mandatoryOptions: {
    latitude: number;
    longitude: number;
    zoomIni: number;
  },
  options: {
    libraries: Libraries;
    mapTypeId: "HYBRID" | "SATELLITE";
  }
): Promise<google.maps.Map> => {
  const mapDiv: HTMLDivElement = document.getElementById(
    "map"
  ) as HTMLDivElement;
  const spinLoader = document.createElement("div");
  spinLoader.classList.add("lds-hourglass");
  mapDiv.appendChild(spinLoader);
  const googleMapLoader = new Loader({
    apiKey: "AIzaSyDqWlykhA8NZyGRc1I2PN4wxKlwhl50Z_Q",
    libraries: options.libraries,
  });
  await googleMapLoader.load();
  addGoogleDistanceFromPrototype();
  const mapOptions: google.maps.MapOptions = {
    zoom: mandatoryOptions.zoomIni,
    scaleControl: true,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
    mapTypeId:
      options.mapTypeId === "HYBRID"
        ? google.maps.MapTypeId.HYBRID
        : google.maps.MapTypeId.SATELLITE,
    center: new google.maps.LatLng(
      mandatoryOptions.latitude,
      mandatoryOptions.longitude
    ),
  };
  const map = new google.maps.Map(
    document.getElementById(divMapId),
    mapOptions
  );
  map.addListener("tileLoaded", () => {
    spinLoader.remove();
  });
  return map;
};

const placeSite = (
  map: google.maps.Map,
  point: google.maps.LatLng | google.maps.LatLngLiteral,
  draggable: boolean
) => {
  map.setCenter(point);
  return new google.maps.Marker({
    position: point,
    map: map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "blue",
      fillOpacity: 0.6,
      scale: 15,
      strokeWeight: 0.75,
      strokeColor: "blue",
      anchor: new google.maps.Point(0, 0),
    },
    zIndex: 1000,
    draggable,
  });
};

export default {
  loadGoogleMap,
  placeSite,
  placePaneliste,
  pointsDInteret,
  afficheQuartier,
  odorConcentrationPoints,
};
